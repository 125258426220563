import { isObject } from '@core/utils/utils'

const selectedValue = (label, value) => `<b>${label}</b>: ${value}`
const mappingFields = (struct, fields, params) => {
  const res = {}
  Object.keys(struct).forEach(field => {
    if (isObject(struct[field])) {
      res[field] = mappingFields(struct[field], fields[field], params[field])
    } else if (fields[field].mapping) {
      res[field] = fields[field].mapping(params[field])
    } else if (isObject(params[field])) {
      res[field] = params[field].id ?? null
    } else {
      res[field] = params[field] ?? null
    }
  })
  return res
}
const getStructOfFilter = (fields, withOutFields = ['options']) => {
  const result = {}
  Object.keys(fields).forEach(field => {
    if (isObject(fields[field]) && !withOutFields.includes(field)) {
      result[field] = getStructOfFilter(fields[field])
    }
  })
  return Object.keys(result).length ? result : null
}

const getSelectedFilters = (struct, filters, fields, path) => {
  let res = []
  Object.keys(struct).forEach(field => {
    if (isObject(struct[field])) {
      res = res.concat(getSelectedFilters(struct[field], filters[field], fields[field], path.concat(field)))
    } else if (fields[field].selectedValue) {
      const selectedVal = fields[field].selectedValue(fields[field].label, filters[field])
      if (selectedVal) {
        res.push({
          value: selectedVal,
          path: path.concat(field),
        })
      }
    } else if (fields[field].selectedLabel) {
      const selectedVal = fields[field].selectedLabel(filters[field])
      if (selectedVal) {
        res.push({
          value: selectedValue(fields[field].label, selectedVal),
          path: path.concat(field),
        })
      }
    } else if (isObject(filters[field])) {
      const last = Object.keys(filters[field]).filter(x => x !== 'id')

      res.push({
        value: selectedValue(fields[field].label, filters[field][last]),
        path: path.concat(field),
      })
    } else if (filters[field] !== '' && filters[field] !== null && filters[field] !== undefined) {
      res.push({
        value: selectedValue(fields[field].label, filters[field]),
        path: path.concat(field),
      })
    }
  })

  return res
}

const changeResponseFilter = (struct, filters, fields) => {
  const res = {}
  Object.keys(struct).forEach(field => {
    if (isObject(struct[field])) {
      res[field] = changeResponseFilter(struct[field], filters[field], fields[field])
    } else if (fields[field].responseFilter) {
      res[field] = fields[field].responseFilter(filters[field])
    } else {
      res[field] = filters[field]
    }
  })
  return res
}
const getLabel = (fields, args) => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < args.length; i++) {
    // eslint-disable-next-line prefer-rest-params,no-param-reassign
    fields = fields[args[i]] ?? {}
  }
  return fields?.label ?? ''
}
const getPlaceholder = (fields, args) => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < args.length; i++) {
    // eslint-disable-next-line prefer-rest-params,no-param-reassign
    fields = fields[args[i]] ?? {}
  }
  return fields?.placeholder ?? ''
}
export default class Filter {
  // eslint-disable-next-line class-methods-use-this
  filterComponent = null

  mapping(params) {
    return mappingFields(this.struct(), this.fields, params)
  }

  // eslint-disable-next-line class-methods-use-this
  mappingBoolean(value) {
    // eslint-disable-next-line no-restricted-globals
    return value !== null && !isNaN(value) ? Number(value) : null
  }

  // eslint-disable-next-line class-methods-use-this
  selectedOnlyLabel(label) {
    return `<b>${label}</b>`
  }

  struct() {
    return getStructOfFilter(this.fields)
  }

  getSelectedFilters(data) {
    return getSelectedFilters(this.struct(), data, this.fields, [])
  }

  getLabel() {
    // eslint-disable-next-line prefer-rest-params
    return getLabel(this.fields, arguments)
  }

  getPlaceholder() {
    // eslint-disable-next-line prefer-rest-params
    return getPlaceholder(this.fields, arguments)
  }

  // eslint-disable-next-line class-methods-use-this
  getResponseFilter(response) {
    return response.data.data?.filter ?? {}
  }

  updateSelectedFilters(data) {
    this.selectedFilters = this.getSelectedFilters(data)
  }

  // eslint-disable-next-line class-methods-use-this
  removeSelectedFilter(filter, path, idx = 0) {
    if (path.length - 1 === idx) {
      // eslint-disable-next-line no-param-reassign
      filter[path[idx]] = null
    } else {
      this.removeSelectedFilter(filter[path[idx]], path, idx + 1)
    }
  }

  changeResponseFilter(filters) {
    return changeResponseFilter(this.struct(), filters, this.fields)
  }
}
