var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('portal',{attrs:{"to":"body-top"}},[_c('h4',{staticClass:"text-center font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('Asset List —'))+" "),(_vm.inventoryWarehouse.id)?_c('span',[_vm._v(" "+_vm._s(_vm.inventoryWarehouse.label)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('————'))+" ")])])]),_c('inventory-list-options'),_c('l-table',{ref:"lTableRef",staticClass:"inventory-list-asset-table",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumnsAssets,"filter-class":new _vm.InventoryAssets(),"table-config-options":{
      endpoint: (_vm.MODULE_NAME + "/getInventoriesList")
    }},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(ref){
    var data = ref.data;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('Can',{attrs:{"i":_vm.ACCESS_ABILITY_INVENTORY.action,"a":_vm.ACCESS_ABILITY_INVENTORY.subject}},[_c('div',{staticClass:"d-flex align-items-center inventory-list-asset-table__actions"},[_c('feather-icon',{staticClass:"cursor-pointer border-dotted feather-trash-icon defaultIconColorTwo",style:(("" + (_vm.getAllowButton(data.item)))),attrs:{"icon":"LRepairIcon","size":"24"},on:{"click":function($event){return _vm.navigateToServiceOrders(data.item)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],class:("cursor-pointer border-dotted feather-trash-icon defaultIconColorTwo " + (data.item.status === _vm.assetStates.TRANSFER.code ? 'disabledCursor' : '')),attrs:{"icon":"LTransferCalendarIcon","size":"24","title":data.item.status === _vm.assetStates.TRANSFER.code ? 'This asset is already transferred' : 'Create a fulfillment transfer'},on:{"click":function($event){return _vm.navigateToTransferOrder(data.item)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"cursor-pointer border-dotted feather-trash-icon defaultIconColorTwo",attrs:{"icon":"LEyeIcon","size":"24","title":"View"},on:{"click":function($event){return _vm.navigateToAsset(data.item)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"cursor-pointer border-dotted feather-trash-icon defaultIconColorTwo",attrs:{"icon":"LHandsIcon","size":"24","title":"Audit"},on:{"click":function($event){return _vm.navigateToAssetAudit(data.item)}}})],1)])],1)]}},{key:"cell(asset_id)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.id)+" ")]}},{key:"cell(sku)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.product !== null ? data.item.product.sku : '---')+" ")]}},{key:"cell(state)",fn:function(ref){
    var item = ref.data.item;
return [_c('state-of-asset',{attrs:{"data":item}})]}},{key:"cell(name)",fn:function(ref){
    var data = ref.data;
return [_c('span',{staticClass:"name-cell-res"},[_vm._v(" "+_vm._s(data.item.product !== null ? data.item.product.name : '---')+" ")])]}}])}),_c('change-asset-to-damaged',{ref:"change-asset-to-damaged"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }