<template>
  <!--  <div :class="`w-100 d-flex ${appWidth ? 'justify-content-start' : 'justify-content-end'}`">-->
  <div class="w-100 d-flex justify-content-start mb-1">
    <div
      class="d-flex"
      style="gap: 12px"
    >
      <b-button
        v-for="{ id, name, title, query } in inventoryProductListTab"
        :key="id"
        :variant="`${query === getRouterPath ? 'secondary': 'secondary-light'}`"
        @click="$router.push({ name })"
      >
        {{ $t(title) }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { VBTooltip, BButton } from 'bootstrap-vue'
import config from '@/views/main/inventory/inventoryConfig'

export default {
  name: 'InventoryListOptions',
  components: {
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      route: this.$route.path.split('/'),
    }
  },
  computed: {
    getRouterPath() {
      return this.route[this.route.length - 1]
    },
    appWindowWidth() {
      return this.$store.state.app.windowWidth
    },
    mobileWindowWidth() {
      return this.$store.state.app.mobileWindowWidth
    },
    appWidth() {
      return this.appWindowWidth < this.mobileWindowWidth
    },
  },
  setup() {
    const { inventoryProductListTab } = config()

    return {
      inventoryProductListTab,
    }
  },
}
</script>
