<template>
  <div>
    <span class="font-weight-bolder ml-1">{{ $t("Upload pictures of the damage ") }}</span>
    <div class="l-file-upload mb-1">
      <div class="d-flex justify-content-end mb-1">
        <feather-icon
          style="min-width: 37px; min-height: 37px"
          icon="LAddButtonIconWhite"
          size="24"
          class="cursor-pointer feather-add-icon "
          @click="$refs.refLFileUploadInput.click()"
        />
      </div>
      <!-- ===================================== -->

      <div class="upload-file">
        <div class="">
          <input
            v-show="false"
            ref="refLFileUploadInput"
            :accept="formattedAcceptProps"
            type="file"
            @input="fileInputChanged"
          >
        </div>
      </div>
      <div
        v-if="!verificationAttachments.length"
      >
        <b-table-simple
          class="upload-attachment-table"
        >
          <b-thead>
            <b-tr>
              <b-th
                v-for="({label, thStyle}, idx) in uploadAttachmentTableCol"
                :key="idx"
                :style="thStyle"
              >
                {{ label }}
              </b-th>
            </b-tr>
          </b-thead>
        </b-table-simple>
        <div
          class="text-center upload-file__files-list-no-data border mb-md-1"
        >
          {{ $t('None Found') }}
        </div>
      </div>

      <div
        v-else
        class="border"
        style="border-radius: 5px"
      >
        <div
          v-if="isLoading"
          class="d-flex justify-content-center my-2"
        >
          <b-spinner />
        </div>
        <div v-if="!isLoading">
          <b-table-simple
            class="upload-attachment-table"
          >
            <b-thead>
              <b-tr>
                <b-th
                  v-for="({label, thStyle}, idx) in uploadAttachmentTableCol"
                  :key="idx"
                  :style="thStyle"
                >
                  {{ label }}
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(file, index) in verificationAttachments"
                :key="index"
              >
                <b-th>
                  <span
                    v-b-tooltip.noninteractive.hover
                    class="file-name"
                    :title="file.id ? file.name : file.file.name"
                  >
                    {{ file.id ? file.name : file.file.name }}
                  </span>
                </b-th>
                <b-th>
                  <span
                    v-b-tooltip.noninteractive.hover
                    :title="file.id ? convertTZ(file.created_at) : '---'"
                    class="file-name"
                  >
                    {{ file.id ? convertTZ(file.created_at) : '---' }}
                  </span>
                </b-th>
                <b-th>
                  <span
                    v-b-tooltip.noninteractive.hover
                    class="file-name"
                    :title="file.id ? file.mime_type : file.file.type"
                  >
                    {{ file.id ? file.mime_type : file.file.type }}
                  </span>
                </b-th>
                <b-th>
                  <span
                    v-b-tooltip.noninteractive.hover
                    class="file-name"
                    :title="getCategoryName(file.attachment_category_id)"
                  >
                    {{ getCategoryName(file.attachment_category_id) }}
                  </span>
                </b-th>
                <b-th>
                  <div class="d-flex justify-content-end">
                    <div class="upload-file__files-list-item-del">
                      <feather-icon
                        icon="LTrashIcon"
                        size="28"
                        style="padding: 5px"
                        class="cursor-pointer mr-1 border-dotted feather-trash-icon"
                        @click="deleteAttachment(file.id, index)"
                      />
                    </div>
                    <div class="d-flex">
                      <span>
                        <span>
                          <feather-icon
                            v-if="file.id"
                            icon="LDownloadIcon"
                            size="28"
                            style="padding: 5px"
                            class="cursor-pointer border-dotted feather-download-icon mr-1"
                            @click="uploadAttachment(file, index)"
                          />
                        </span>
                      </span>
                      <span>
                        <a @click="showAttachment(file)">
                          <feather-icon
                            icon="LEyeIcon"
                            size="28"
                            style="padding: 5px"
                            class="cursor-pointer border-dotted feather-eye-icon"
                          />
                        </a>
                      </span>
                    </div>
                  </div>
                </b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>

      </div>
      <div class="l-file-upload">
        <div
          class="l-file-upload__dragzone"
          @dragover.prevent
          @click="clickFileInput"
          @drop.prevent="dropped"
        >
          <p
            class="l-file-upload__dragzone-text"
            :style="{fontWeight: fileName ? '700':'400'}"
          >
            {{ `${fileName ? fileName : 'Drop Files Here to Upload' }` }}
          </p>
          <input
            v-show="false"
            ref="refLFileUploadInput"
            :accept="formattedAcceptProps"
            type="file"
            @input="fileInputChanged"
          >
        </div>
      </div>
      <!-- ===================================== -->
    </div>
    <b-modal
      id="attachment-create-modal"
      centered
      hide-header
      body-class="l-modal__body"
      footer-class="l-modal-footer"
      ok-title="Upload"
      ok-variant="success"
      :ok-disabled="isOkUploadBtnActive"
      :cancel-disabled="isCancelUploadBtnActive"
      cancel-variant="outline-primary"
      @hidden="clearData"
      @ok="uploadFile"
    >
      <b-overlay :show="isUploading">
        <div class="l-modal__container">
          <b-row>
            <b-col cols="12">
              <h3 class="l-modal__title">
                {{ $t('What category is this file?') }}
              </h3>
            </b-col>
            <b-col>
              <l-select
                v-model="sendData.attachment_category_id"
                name="attachment_category_id"
                :field="{
                  options: { transition: '' },
                  store: 'attachmentCategoryListInventoryAssetDamaged',
                }"
              />
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BThead,
  BTr,
  BTh,
  BModal,
  BOverlay,
  BSpinner,
  BTableSimple,
  BTbody,
  VBTooltip,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import axios from 'axios'
import config from '../config'

export default {
  name: 'Attachments',
  components: {
    BRow,
    BCol,
    BThead,
    BTr,
    BTh,
    BModal,
    BOverlay,
    BTbody,
    BSpinner,
    BTableSimple,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      uploadedFiles: [],
      attachments: [],
      isUploading: false,
      isLoading: false,
      fileName: null,
      isMovingToTrash: false,
      inputValue: null,
      chosenFiles: null,
      moveToTrashItemId: null,
      allowedFormats: ['jpg', 'jpeg', 'png', 'tif', 'pdf', 'bmp'],
      sendData: {
        attachment_category_id: null,
        model_type: 1,
        file: null,
      },
    }
  },
  computed: {
    formattedAcceptProps() {
      return ['.jpg', '.jpeg', '.png', '.tif', '.pdf', '.bmp']
    },
    verificationAttachments() {
      return this.$store.state[this.MODULE_NAME].changeAssetToDamagedForm.attachments ?? []
    },
    attachmentCategoryListInventoryAssetDamaged() {
      return this.$store.state.listModule.attachmentCategoryListInventoryAssetDamaged ?? []
    },
    isOkUploadBtnActive() {
      return this.isUploading || !this.sendData.attachment_category_id
    },
    isCancelUploadBtnActive() {
      return this.isUploading
    },
    isOkTrashBtnActive() {
      return this.isUploading
    },
    isCancelTrashBtnActive() {
      return this.isUploading
    },
    files() {
      return this.uploadedFiles.map(file => ({
        name: file.file.name,
      }))
    },
  },
  methods: {
    showModalToChoseCategory() {
      this.$bvModal.show('attachment-create-modal')
    },
    hideModalToChoseCategory() {
      this.$bvModal.hide('attachment-create-modal')
    },
    showAttachment(file) {
      if (file.id) {
        window.open(file.url, '_blank')
      } else {
        console.log(file)
        window.open(
          (window.URL || window.webkitURL).createObjectURL(file.file),
          '_blank',
        )
      }
    },
    dropped(e) {
      if (!e.dataTransfer.files[0]) return
      this.fileName = e.dataTransfer.files[0].name
      this.$emit('input', this.onlyAcceptableFiles(e.dataTransfer.files[0]))
    },
    onlyAcceptableFiles(filesArray) {
      // todo add check for it
      return filesArray
    },
    fileInputChanged(e) {
      this.chosenFiles = e.target.files
      this.showModalToChoseCategory()
    },
    clickFileInput() {
      this.$refs.refLFileUploadInput.click()
    },
    clearData() {
      this.sendData.attachment_category_id = null
      this.sendData.file = null
      this.chosenFiles = null
      this.$refs.refLFileUploadInput.value = null
    },
    mapDataObject() {
      return {
        ...this.sendData,
        attachment_category_id: this.sendData.attachment_category_id.id,
        file: this.chosenFiles[0],
      }
    },
    getCategoryName(id) {
      return this.attachmentCategoryListInventoryAssetDamaged.find(item => +item.id === +id)?.name ?? 'no category adw aw daw daw d awd awd'
    },
    postData() {
      this.isUploading = false
      this.hideModalToChoseCategory()
    },
    checkTheFileType(file) {
      const fileType = file.split('/')[1]
      let isErrorAvailable = false
      if (!this.allowedFormats.includes(fileType)) {
        isErrorAvailable = true
        this.$bvToast.toast(this.$t('File format is not accepted'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        this.postData()
      }
      return isErrorAvailable
    },
    checkTheFileSize(theFileSize) {
      console.log('theFileSize', theFileSize)
      let isErrorAvailable = false
      if (theFileSize > 4 * 1024 * 1024) {
        // 4MB limit
        isErrorAvailable = true
        this.hideModalToChoseCategory()
        this.$bvToast.toast(this.$t('File size exceeds the limit of 4MB. Please choose a smaller file'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        this.postData()
      }
      return isErrorAvailable
    },
    uploadFile() {
      this.isUploading = true
      if (this.checkTheFileType(this.mapDataObject().file.type)) {
        return
      }
      if (this.checkTheFileSize(this.mapDataObject().file.size)) {
        return
      }
      this.verificationAttachments.push(this.mapDataObject())
      this.attachments.push(this.mapDataObject())
      this.postData()
    },
    deleteAttachment(id, index) {
      if (id) {
        this.isLoading = true
        this.$store
          .dispatch(`${this.MODULE_NAME}/deleteAttachment`, { id })
          .catch(() => {
            this.isLoading = false
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: i18n.t('Error Deleting This file', {
                  module: this.MODULE_NAME,
                }),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {

          })
      } else {
        this.verificationAttachments.splice(index, 1)
      }
    },
    uploadAttachment(file) {
      console.log(file)
      if (file) {
        this.show = true
        const fileUrl = file.url
        axios({
          url: fileUrl,
          method: 'GET',
          responseType: 'blob',
        }).then(res => {
          const fileBlob = window.URL.createObjectURL(new Blob([res.data]))
          const fileLink = document.createElement('a')
          fileLink.href = fileBlob
          const fileName = fileUrl.split('/').pop()
          fileLink.setAttribute('download', fileName)
          fileLink.setAttribute('id', 'test-download')
          // fileLink.setAttribute('target', '_blank')
          document.body.appendChild(fileLink)
          fileLink.click()
        }).catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: i18n.t('Error Uploading This file', {
                module: this.MODULE_NAME,
              }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.show = false
        })
      }
    },

  },
  setup() {
    const MODULE_NAME = 'inventories'
    const { uploadAttachmentTableCol } = config()

    return {
      MODULE_NAME,
      uploadAttachmentTableCol,
    }
  },
}
</script>
<style lang="scss" scoped>
.is-editable-file-upload {
  opacity: 0.5;
  pointer-events: none;
}

.upload-attachment-table {
  margin-bottom: 0;

  th {
    padding: 5px !important;
    font-weight: normal;
  }

  & .file-name,
  & .file-data {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 24px;
  }

  &--disabled{
    pointer-events: none;
    opacity: 0.5;
  }

}
</style>
