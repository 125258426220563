import { ref } from '@vue/composition-api'
import Filter from './Filter'

export default class InventoryAssets extends Filter {
    filterComponent = import('@/views/components/filters/InventoryAssetsFilterComponent.vue')

    static MODULE_NAME = 'inventories'

    static NAME = 'name'

    static TAGS = 'tags'

    static PICK_LOCATION = 'location_id'

    static CONDITION = 'condition_id'

    static MODEL = 'model'

    static BRAND = 'brand'

    static PRODUCT_RULES = 'rules'

    static CAN_BE_SOLD = 'can_be_sold'

  static ALLOW_TO_SELL = 'allowed_to_sell'

  static IN_ACTIVE = 'inactive'

  static SERVICE = 'service'

  static DAMAGED = 'damaged'

  static SUBRENTAL = 'subrental'

    fields = {
      [InventoryAssets.NAME]: {
        label: 'Name',
        placeholder: 'Type...',
      },
      [InventoryAssets.TAGS]: {
        label: 'Tags',
        store: 'tagsList',
        options: {
          multiple: true,
          label: 'label',
        },
        mapping(value) {
          if (Array.isArray(value)) return value.map(item => (item.id ? item.id : item))
          return []
        },
        selectedLabel(value) {
          if (Array.isArray(value)) {
            return value.map(item => item.label).join(', ')
          }
          return value
        },
      },
      [InventoryAssets.CONDITION]: {
        label: 'Condition',
        store: 'assetConditionList',
      },
      [InventoryAssets.PICK_LOCATION]: {
        label: 'Pick Location',
      },
      [InventoryAssets.MODEL]: {
        label: 'Model',
        placeholder: 'Type...',

      },
      [InventoryAssets.BRAND]: {
        label: 'Brand',
        store: 'brandsList',
      },
      [InventoryAssets.PRODUCT_RULES]: {
        [InventoryAssets.CAN_BE_SOLD]: {
          label: 'Allow to sell',
          selectedValue: this.selectedOnlyLabel,
        },
        [InventoryAssets.ALLOW_TO_SELL]: {
          label: 'Allow to sell',
          selectedValue: this.selectedOnlyLabel,
        },
        [InventoryAssets.NEEDS_INDIVIDUAL_ASSET_ID]: {
          label: 'Items with no assets',
          selectedValue: this.selectedOnlyLabel,
        },
        [InventoryAssets.IN_ACTIVE]: {
          label: 'Inactive',
          selectedValue: this.selectedOnlyLabel,
        },
        [InventoryAssets.DAMAGED]: {
          label: 'Damaged',
          selectedValue: this.selectedOnlyLabel,
        },
        [InventoryAssets.SERVICE]: {
          label: 'Service',
          selectedValue: this.selectedOnlyLabel,
        },
        [InventoryAssets.SUBRENTAL]: {
          label: 'Subrental',
          selectedValue: this.selectedOnlyLabel,
        },
      },
    }

    selectedFilters = ref()

    // eslint-disable-next-line class-methods-use-this
    getResponseFilter(response) {
      return response.data.meta.filters ?? {}
    }

    selectedOnlyLabel(label, value) {
      if (value) return super.selectedOnlyLabel(label)
      return null
    }
}
