<template>
  <div class="pb-2">
    <portal to="body-top">
      <h4 class="text-center font-weight-bolder">
        {{ $t('Asset List —') }}
        <span
          v-if="inventoryWarehouse.id"
        >
          {{ inventoryWarehouse.label }}
        </span>
        <span v-else>
          {{ $t('————') }}
        </span>
      </h4>
    </portal>
    <inventory-list-options />
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumnsAssets"
      class="inventory-list-asset-table"
      :filter-class="new InventoryAssets()"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/getInventoriesList`
      }"
    >
      <!--      <template #warning-icon-on-search>-->
      <!--        <b-button-->
      <!--          class="searchButton"-->
      <!--          style="background-color: #D9D9D9 !important; margin-left: 0 !important;"-->
      <!--          @click="warningClicked"-->
      <!--        >-->
      <!--          <feather-icon-->
      <!--            icon="LWarningGreyIcon"-->
      <!--            size="16"-->
      <!--          />-->
      <!--        </b-button>-->
      <!--      </template>-->
      <!--      <template #table-top-right-side-extras-next>-->
      <!--      </template>-->
      <template #cell(actions)="{data}">
        <div class="d-flex align-items-center">
          <Can
            :i="ACCESS_ABILITY_INVENTORY.action"
            :a="ACCESS_ABILITY_INVENTORY.subject"
          >
            <div class="d-flex align-items-center inventory-list-asset-table__actions">
              <feather-icon
                icon="LRepairIcon"
                size="24"
                class="cursor-pointer border-dotted feather-trash-icon defaultIconColorTwo"
                :style="`${getAllowButton(data.item)}`"
                @click="navigateToServiceOrders(data.item)"
              />
              <feather-icon
                v-b-tooltip.noninteractive.hover
                icon="LTransferCalendarIcon"
                size="24"
                :class="`cursor-pointer border-dotted feather-trash-icon defaultIconColorTwo ${data.item.status === assetStates.TRANSFER.code ? 'disabledCursor' : ''}`"
                :title="data.item.status === assetStates.TRANSFER.code ? 'This asset is already transferred' : 'Create a fulfillment transfer'"
                @click="navigateToTransferOrder(data.item)"
              />
              <feather-icon
                v-b-tooltip.noninteractive.hover
                icon="LEyeIcon"
                size="24"
                title="View"
                class="cursor-pointer border-dotted feather-trash-icon defaultIconColorTwo"
                @click="navigateToAsset(data.item)"
              />
              <feather-icon
                v-b-tooltip.noninteractive.hover
                icon="LHandsIcon"
                size="24"
                title="Audit"
                class="cursor-pointer border-dotted feather-trash-icon defaultIconColorTwo"
                @click="navigateToAssetAudit(data.item)"
              />
            </div>
          </Can>
        </div>
      </template>
      <template #cell(asset_id)="{ data }">
        {{ data.item.id }}
      </template>
      <template #cell(sku)="{ data }">
        {{ data.item.product !== null ? data.item.product.sku : '---' }}
      </template>
      <template #cell(state)="{ data: { item } }">
        <state-of-asset
          :data="item"
        />
      </template>
      <template #cell(name)="{ data }">
        <span class="name-cell-res">
          {{ data.item.product !== null ? data.item.product.name : '---' }}
        </span>
      </template>
    </l-table>
    <change-asset-to-damaged ref="change-asset-to-damaged" />
  </div>
</template>

<script>
import LTable from '@/views/components/LTable/LTable.vue'
import { BButton, VBTooltip } from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import config from '@/views/main/inventory/inventoryConfig'
// Components
// eslint-disable-next-line import/no-cycle
import InventoryAssets from '@/views/components/filters/fields/InventoryAssets'
import ChangeAssetToDamaged
from '@/views/main/inventory/inventory-view/components/ChangeAssetToDamaged/ChangeAssetToDamaged.vue'
import StateOfAsset from '@/views/main/inventory/inventory-view/components/StateOfAsset.vue'
import { assetStates } from '@/enum/asset-statuses'
import InventoryListOptions from '../components/InventoryListOptions.vue'

export default {
  name: 'InventoryListAssets',
  components: {
    BButton,
    StateOfAsset,
    ChangeAssetToDamaged,
    LTable,
    InventoryListOptions,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      chosenFiles: null,
      batchDeleteArr: [],
      assetStates,
    }
  },
  computed: {
    customers() {
      return this.$store.state[this.MODULE_NAME].customers
    },
    inventoryWarehouse() {
      return this.$store.state.app.warehouse
    },
    InventoryAssets() {
      return InventoryAssets
    },
    warehouse() {
      return this.$store.state.app.warehouse
    },
  },
  created() {
    this.$emit('updateMenu', 'home-inventory-list')
    this.$store.commit(`${this.MODULE_NAME}/GET`, {})
    this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORY_ID`, null)
    this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORY_SERIALIZED_AND_NON_SERIALIZED_VAL`, null)
  },
  methods: {
    // isInventorySerialized(assetsItem) {
    //   return assetsItem?.item?.product?.needs_individual_asset_id
    // },
    showModalToChoseCategory() {
      this.$bvModal.show('customer-list-file-upload_download')
    },
    hideModalToChoseCategory() {
      this.$bvModal.hide('customer-list-file-upload_download')
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    navigateToTransferOrder(item) {
      const { id, product_id, status } = item
      if (status === assetStates.TRANSFER.code) return

      this.$router.push({ name: 'home-inventory-transfer-order-by-asset-id', params: { id, product_id } })
    },
    getAllowButton(item) {
      const { status } = item
      return status === assetStates.SERVICE.code ? 'cursor:not-allowed' : 'cursor:pointer'
    },
    navigateToServiceOrders(item) {
      const { id, product_id, status } = item
      if (status === assetStates.SERVICE.code) return
      this.showAssetToDamagedModal(item)
    },
    navigateToAsset(item) {
      const { id, product_id } = item

      this.$router.push({ name: 'home-inventory-item-update', params: { id: product_id, inv_id: id } })
    },
    navigateToAssetAudit(item) {
      const { id, product_id } = item

      this.$router.push({ name: 'home-inventory-item-audit', params: { id: product_id, inv_id: id } })
    },
    navigateTo({ id }, e, name) {
      if (e.ctrlKey) {
        const route = this.$router.resolve({
          name,
          params: { inv_id: id },
        })
        window.open(route.href)
      } else {
        this.$router.push({
          name,
          params: { inv_id: id },
        })
      }
    },
    update(data) {
      this.$router.push({
        name: 'customer-update',
        params: { id: data.id },
      })
    },
    // eslint-disable-next-line consistent-return
    getData(data, dataName) {
      const { item: { default_contact } } = data
      if (dataName === 'fullname') {
        // eslint-disable-next-line camelcase
        return default_contact ? `${default_contact.first_name} ${default_contact.last_name}` : '—'
      }
      if (dataName === 'phone') {
        // eslint-disable-next-line camelcase
        return default_contact ? `${default_contact.phone_no}` : '—'
      }
    },
    renderSuggestion(suggestion) {
      const { item } = suggestion
      return item.cust_name_dba + (item.default_contact ? `: ${this.getData(suggestion, 'fullname')}` : '')
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return item.cust_name_dba
    },
    getSearchProps() {
      return {
        'render-suggestion': this.renderSuggestion,
        'get-suggestion-value': this.getSuggestionValue,
      }
    },
    showAssetToDamagedModal({
      id, status, last_order,
    }) {
      if (status === assetStates.DAMAGED.code) {
        this.$router.push({ name: 'home-orders-request-service-quote', params: { id } })
      } else {
        this.$refs['change-asset-to-damaged'].show()
        this.$store.commit(`${this.MODULE_NAME}/SET_DAMAGED_INVENTORY_ITEM_ID`, id)
        this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORY_LAST_ORDER`, last_order)
        this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORIES_LIST_REF`, this.$refs)
      }
    },
    warningClicked() {
      console.log('Warning Clicked !')
    },
  },
  setup() {
    const PRODUCT_MODULE_NAME = 'product'
    const MODULE_NAME = 'inventories'

    const {
      tableColumnsAssets, ACCESS_ABILITY_INVENTORY,
    } = config()
    return {
      tableColumnsAssets,
      PRODUCT_MODULE_NAME,
      MODULE_NAME,
      ACCESS_ABILITY_INVENTORY,
    }
  },
}
</script>

<style scoped lang="scss">
.inventory-list-asset-table {

  &__actions {
    gap: 12px;
  }

  @media only screen and (max-width: 1100px) {

    & table thead tr th:nth-child(3) {
      width: 30% !important;
    }

    .name-cell-res {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 24px;
    }

    &__actions {
      gap: 8px;
    }
  }

}

.disabledCursor {
  cursor: not-allowed;
  background: #EBECF1;
}
</style>
