import {
  CHECKBOX_LEF, SELECT_INPUT, TEXT_INPUT,
} from '@/views/components/DynamicForm/constants'

export default function config() {
  const MODAL_TITLE_PREFIX = 'Asset'
  const MODAL_TITLE_SUFFIX = 'Mirrorless'
  const MODAL_ID = 'change-asset-to-damaged-modal'

  const fields = {
    describe_the_issue: {
      type: TEXT_INPUT,
      label: 'Describe the issue',
      rules: 'required',
    },
    issue_type: {
      type: SELECT_INPUT,
      label: 'Issue Type',
      store: 'issueTypeList',
      rules: 'required',
    },
    is_bill: {
      type: CHECKBOX_LEF,
      label: 'Bill',
    },
  }

  const uploadAttachmentTableCol = [
    {
      label: 'File Name',
      thStyle: {
        width: '25%',
        fontWeight: 'bold',
      },
    },
    {
      label: 'Date',
      thStyle: {
        width: '15%',
        fontWeight: 'bold',
      },
    },
    {
      label: 'Type',
      thStyle: {
        width: '10%',
        fontWeight: 'bold',
      },
    },
    {
      label: 'Category',
      thStyle: {
        width: '23%',
        fontWeight: 'bold',
      },
    },
    {
      label: '',
      thStyle: {
        width: '21%',
        fontWeight: 'bold',
      },
    },
  ]

  const FORM_CANCEL_BTN = 'Cancel'
  const FORM_CANCEL_REPORT = 'Report'

  return {
    fields,
    MODAL_ID,
    FORM_CANCEL_BTN,
    MODAL_TITLE_PREFIX,
    MODAL_TITLE_SUFFIX,
    FORM_CANCEL_REPORT,
    uploadAttachmentTableCol,
  }
}
