<template>
  <div class="d-flex align-items-center">
    <div>
      <feather-icon
        :icon="`${statusOfItem.icon}`"
        :style="`${statusOfItem.style}`"
      />
      <span v-show="withLabel">
        {{ statusOfItem ? statusOfItem.label : '-' }}
      </span>
    </div>
  </div>
</template>

<script>
import { getValueFromGivenObjectByKey } from '@/@core/utils/utils'
import { assetStates } from '@/enum/asset-statuses'

export default {
  name: 'StateOfAsset',
  props: {
    data: {
      type: Object,
      required: true,
    },
    withLabel: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    damaged() {
      return this.data[this.DAMAGED_ASSET_KEY]
    },
    transferOrder() {
      return this.data[this.TRANSFER_ASSET_KEY]
    },
    statusOfItem() {
      return Object.entries(assetStates).find(([key, value]) => value.code === this.data?.status)?.[1]
    },
  },
  setup() {
    const DAMAGED_ASSET_KEY = 'damaged'
    const TRANSFER_ASSET_KEY = 'transfer_order'
    const DAMAGED_ASSET_TITLE = 'Damaged'
    const SERVICE_ASSET_TITLE = 'Service'

    return {
      DAMAGED_ASSET_KEY,
      TRANSFER_ASSET_KEY,
      DAMAGED_ASSET_TITLE,
      SERVICE_ASSET_TITLE,
      getValueFromGivenObjectByKey,
    }
  },
}
</script>
<style lang="scss"></style>
